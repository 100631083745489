// The basics
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import Routes from "./routes";
import EmpresaContext from "./context";

import Spinner from "react-spinner-material";
import Maintenance from "./components/maintenance";
import UnderConstruction from "./components/under-construction";
import BuilderInterface from "./components/builder-hover";
import get from "./client_api";
import Loadable from "react-loadable";
require("isomorphic-unfetch");

let Loading = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "50vh"
    }}
  >
    <Spinner
      radius={30}
      color={"var(--main-color-one)"}
      stroke={3}
      visible={true}
    />
  </div>
);

const WithBuilder = Loadable({
  loader: () =>
    import(/* webpackChunkName: "builder" */ "./components/builder"),
  loading: Loading,
  modules: ["builder"]
});

class App extends Component {
  state = {
    isBuilder: this.props.isBuilder,
    empresaInitialState: this.props.empresa
  };
  setEmpresa = change => {
    this.setState(pS => ({
      ...pS,
      empresaInitialState: { ...pS.empresaInitialState, ...change }
    }));
  };
  componentDidMount() {
    if (window.location.href.includes("localhost:3000")) {
      get("http://localhost:8080", "empresa_data").then(({ empresa }) =>
        this.setEmpresa(empresa)
      );
    }
    console.log("empresa", this.props.empresa);
    if (!window.track) {
      try {
        if (window.SmartTrack) {
          window.track = new window.SmartTrack(this.props.empresa.db_id, {
            noRouter: true
          });
        } else {
          document.addEventListener(
            "DOMContentLoaded",
            () =>
              (window.track = new window.SmartTrack(this.props.empresa.db_id, {
                noRouter: true
              }))
          );
        }
      } catch (error) {
        console.log("TrackError:", error);
      }
    }
  }
  render() {
    return (
      <div id="app">
        <div id="content">
          <EmpresaContext.Provider
            value={{
              ...this.state.empresaInitialState,
              setEmpresa: this.setEmpresa
            }}
          >
            {this.props.empresa.under_construction ? (
              <UnderConstruction />
            ) : (
              <>
                <BuilderInterface />
                {this.props.empresa.maintenance && <Maintenance />}
                <Routes
                  empresa_id={this.props.empresa.db_id}
                  favicon={this.props.empresa.favicon}
                />
              </>
            )}
          </EmpresaContext.Provider>
        </div>
      </div>
    );
  }
}

const empresaMock = {
  use_offline: true,
  home_busca_preco: true,
  depoimentos: [
    {
      foto:
        "https://firebasestorage.googleapis.com/v0/b/smartimob-dev-test.appspot.com/o/empresas%2FAfrS6hMO8oQVAW8r5MqF%2FGlaucinei.jpg?alt=media&token=d36b6c42-e09a-4957-a54d-ece1c193bd47",
      nome: "Glaucinei Treviso",
      texto:
        "Da venda ao pós venda, um atendimento sempre muito profissional. Satisfeito com a compra, com a forma de aquisição, e principalmente, com a rapidez na solução dos problemas. Uma equipe fantástica, preparada para atender minhas necessidades."
    },
    {
      foto:
        "https://firebasestorage.googleapis.com/v0/b/smartimob-dev-test.appspot.com/o/empresas%2FAfrS6hMO8oQVAW8r5MqF%2FMarlon.jpg?alt=media&token=07217be1-a0d9-4395-8aad-92afbda7f6ca",
      nome: "Marlon Suzana",
      texto:
        "Empresa que se moderniza e se preocupa com a qualidade de seus produtos para atender seus clientes com excelência. Ótimas opções de imóveis para comprar ou alugar. Parabéns a todos os envolvidos!"
    },
    {
      foto:
        "https://firebasestorage.googleapis.com/v0/b/smartimob-dev-test.appspot.com/o/empresas%2FAfrS6hMO8oQVAW8r5MqF%2FIsabel.jpg?alt=media&token=bdf6cfba-a079-4496-8ae5-740310933b15",
      nome: "Isabel Rissi",
      texto:
        "Sou cliente e recomendo a Imobiliaria Lorenzon pelo seu excelente atendimento em todas as etapas da negociação. Colaboradores especializados em direcionar para a necessidade do cliente. Agradeço pela eficiência, profissionalismo e atenção de toda a equipe. Muito obrigada!!!"
    }
  ],
  CNPJ: "68639600015",
  CRECI: "61939",
  area_cliente: null,
  area_proprietario: null,
  bairros_disponiveis: [
    {
      bairro: "Centro",
      cidade: 4309100,
      estado: 43,
      id: "rp1o4xfcKNkbXgdBKDUV"
    },
    {
      bairro: "Bavária",
      cidade: 4309100,
      estado: 43,
      id: "vpgN1UIzQewlE6bn257c"
    },
    {
      bairro: "Aspen Montain",
      cidade: 4309100,
      estado: 43,
      id: "NhYhlyNk8fOLSJ8sQ7TZ"
    },
    {
      bairro: "Centro",
      cidade: 4304408,
      estado: 43,
      id: "RnLLnmoCF2wkl7IgZJTd"
    },
    {
      bairro: "Centro",
      cidade: 4304408,
      estado: 43,
      id: "L78UAfjYJpsNOoNnXC9H"
    },
    {
      bairro: "planalto",
      cidade: 4309100,
      estado: 43,
      id: "dlibDvHwRWvlYGa6q4Hd"
    },
    {
      bairro: "Centro",
      cidade: 4309100,
      estado: 43,
      id: "qqzS2aeZKL04eJCZ0uVl"
    },
    {
      bairro: "Planalto",
      cidade: 4309100,
      estado: 43,
      id: "S3qROveLXeLxg7k2akTg"
    },
    {
      bairro: "Bela Vista",
      cidade: 4314902,
      estado: 43,
      id: "5R3a39bAaed1aS2p3kRu"
    },
    { bairro: "Jardim Europa", cidade: 4314902, estado: 43, id: "28433" },
    { bairro: "Moinhos de Vento", cidade: 4314902, estado: 43, id: "28397" },
    { bairro: "Mont'Serrat", cidade: 4314902, estado: 43, id: "28382" },
    { bairro: "Aspen Mountain", cidade: 4309100, estado: 43, id: "28349" },
    { bairro: "Jardim Europa", cidade: 4314902, estado: 43, id: "28312" },
    { bairro: "Bela Vista", cidade: 4314902, estado: 43, id: "28285" },
    { bairro: "Três Figueiras", cidade: 4314902, estado: 43, id: "28244" },
    { bairro: "Bela Vista", cidade: 4314902, estado: 43, id: "28227" },
    { bairro: "Rio Branco", cidade: 4314902, estado: 43, id: "28225" },
    { bairro: "Centro", cidade: 4309209, estado: 43, id: "28199" },
    { bairro: "Chácara das Pedras", cidade: 4314902, estado: 43, id: "28180" },
    { bairro: "Praia Grande", cidade: 4321501, estado: 43, id: "28173" },
    { bairro: "Praia Grande", cidade: 4321501, estado: 43, id: "28141" },
    { bairro: "Walachai", cidade: 4312476, estado: 43, id: "28135" },
    { bairro: "Centro", cidade: 4304630, estado: 43, id: "28114" },
    { bairro: "centro", cidade: "Rua 7 de Julho ", estado: 43, id: "28103" }
  ],
  banner: [
    "https://firebasestorage.googleapis.com/v0/b/smartimob-dev-test.appspot.com/o/empresas%2FaZgzqSolp51uBk5T6ojh%2Frsz_banner_rafael.jpg?alt=media&token=80ecb3a6-de1a-4260-9ef1-2421ddbad366"
  ],
  botao_site_externo: { ativado: false, link: "", nome_link: "#" },
  campos_personalizados: [],
  caracteristicas_disponiveis: [
    { id: "piscina_aquecida", nome: "Piscina Aquecida" },
    { id: "ar_condicionado", nome: "Ar Condicionado" },
    { id: "churrasqueira", nome: "Churrasqueira" },
    { id: "pé_direito_duplo", nome: "Pé Direito Duplo" },
    { id: "closet", nome: "Closet" },
    { id: "lareira", nome: "Lareira" },
    { id: "cozinha_montada", nome: "Cozinha Montada" },
    { id: "piso_laminado", nome: "Piso Laminado" },
    { id: "Lareira", nome: "Lareira" }
  ],
  cidade: '{"nome":null,"value":null}',
  clientes_total: 3,
  clientes_total_datas: { _6_2020: 3 },
  corretor_padrao: "XRhyXX9TgrOeSU3By2Ywyj2VGWF2",
  db_id: "aZgzqSolp51uBk5T6ojh",
  descrição: "",
  email: "contato@smtximob.com",
  endereço: "",
  entradas_clientes: ["Pré-venda", "Em andamento", "Finalizada"],
  estado: '{"nome":null,"value":null}',
  estado_padrao: 43,
  exibir_visualizacoes: false,
  facebook_pixel: "",
  favicon: "",
  frase_destaques: "",
  frase_lançamentos: "",
  frase_topo: {
    frase: "Aprecie a experiência de conhecer os imóveis mais incriveis.",
    max_width: "650px"
  },
  google_code: "",
  horario_funcionamento: "",
  imoveis_total: 91,
  imoveis_total_tipos: { Apartamento: 11, Casa: 1, Loft: 2, null: null },
  locais_disponiveis: [],
  logo:
    "https://firebasestorage.googleapis.com/v0/b/smartimob-dev-test.appspot.com/o/smtx%2Flogo-smart-minimal.png?alt=media&token=25b77e0d-3f44-40bc-a60d-87a4c67c1191&_gl=1*9r767e*_ga*MzI1NzUyMTg1LjE2ODUzNjY0Mjg.*_ga_CW55HF8NVT*MTY4NTcyODk2MS43LjEuMTY4NTczMjAzMi4wLjAuMA..",
  logo_escrita: "",
  midia: [],
  mostrar_agenciador: true,
  mostrar_estados: false,
  nome: "Rafael Odone Broker",
  nome_fantasia: "Rafael Odone",
  palavras_chave: "Rafael Odone Broker",
  palleta: {
    cor_auxiliar: "#47a5ae",
    cor_botão_topo: "#1a1a1a",
    cor_primaria: "#242424",
    cor_rodape: "#363636",
    cor_secundaria: "#ffffff",
    cor_texto_topo: "#fff",
    cor_topo: "transparent"
  },
  possui_locacao: true,
  possui_venda: true,
  redes_sociais: [
    { nome: "instagram", url: "https://www.instagram.com/rafael.odone/" },
    { nome: "facebook", url: "https://www.facebook.com/rafa.odone/" },
    {
      nome: "youtube",
      url:
        "https://www.youtube.com/channel/UCpmB9obZ-d5kyBKcnwrt_kg/videos?view_as=subscriber"
    },
    {
      nome: "linkedin",
      url: "https://www.linkedin.com/in/rafael-odone-wilbert-66118292/"
    }
  ],
  scripts_extras:
    '<meta name="google-site-verification" content="pT6azryvHVxtCspdwfi-Kgag3fADtbxY21-Sa6LarAQ" />\n<!-- Global site tag (gtag.js) - Google Analytics --> <script async src="https://www.googletagmanager.com/gtag/js?id=UA-166362350-1"></script> <script> window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag(\'js\', new Date()); gtag(\'config\', \'UA-166362350-1\'); </script>\n<script type="text/javascript"> _linkedin_partner_id = "1784618"; window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; window._linkedin_data_partner_ids.push(_linkedin_partner_id); </script><script type="text/javascript"> (function(){var s = document.getElementsByTagName("script")[0]; var b = document.createElement("script"); b.type = "text/javascript";b.async = true; b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; s.parentNode.insertBefore(b, s);})(); </script> <noscript> <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=1784618&fmt=gif" /> </noscript>\n<script type="text/javascript"> (function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo"); vgo(\'setAccount\', \'1000673018\'); vgo(\'setTrackByDefault\', true); vgo(\'process\'); </script>',
  telefone_empresa: ["(51) 99372-9601"],
  tipos_disponiveis: [
    { id: "casa", nome: "Casa" },
    { id: "apartamento", nome: "Apartamento" },
    { id: "terreno", nome: "Terreno" },
    { id: "Loft", nome: "Loft" },
    { id: "Casa em Construção", nome: "Casa em Construção" },
    { id: "Sala Comercial", nome: "Sala Comercial" },
    { id: "Consultório", nome: "Consultório" },
    { id: "Cobertura", nome: "Cobertura" },
    { id: "Hotel", nome: "Hotel" }
  ],
  titulo_site: "Rafael Odone Broker",
  ultimo_id: 15,
  url: ["localhost:4000", "urlpadrao.com.br", "localhost:3000"],
  url_compartilhamento: "urlpadrao.com.br",
  var_basicas: true,
  video_topo:
    "https://firebasestorage.googleapis.com/v0/b/smartimob-dev-test.appspot.com/o/empresas%2FaZgzqSolp51uBk5T6ojh%2FEntrada%20Site%20Rafael.mp4?alt=media&token=f513ea7c-b2c0-43c0-b2ad-1b2850531921"
};

const mapStateToProps = state => ({
  empresa: state.empresa.empresa.nome ? state.empresa.empresa : empresaMock,
  isBuilder: state.empresa.empresa.isBuilder
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(App)
);

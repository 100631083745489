import React, { useContext } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Loadable from "react-loadable";
import { Helmet } from "react-helmet";
import EmpresaContext from "../context";
import parse from "html-react-parser";
import Loading from "../components/global-components/Loading";
import Whatsapp from "../components/global-components/btn-whats";
import CookieBox from "../components/global-components/cookie-box";

/**
 * routes
 */
const Home = Loadable({
  loader: () => import(/* webpackChunkName: "home" */ "./home"),
  loading() {
    return <Loading height="100vh" />;
  },
  modules: ["home"]
});

const Error = Loadable({
  loader: () => import(/* webpackChunkName: "error" */ "./error"),
  loading: Loading,
  modules: ["error"]
});

const Property = Loadable({
  loader: () => import(/* webpackChunkName: "property" */ "./property"),
  loading: Loading,
  modules: ["property"]
});

const Condominium = Loadable({
  loader: () => import(/* webpackChunkName: "condominium" */ "./condominium"),
  loading: Loading,
  modules: ["condominium"]
});

const Contact = Loadable({
  loader: () => import(/* webpackChunkName: "contact" */ "./contact"),
  loading: Loading,
  modules: ["contact"]
});

const About = Loadable({
  loader: () => import(/* webpackChunkName: "about" */ "./about"),
  loading: Loading,
  modules: ["about"]
});

const Lista = Loadable({
  loader: () => import(/* webpackChunkName: "lista" */ "./lista"),
  loading: Loading,
  modules: ["lista"]
});

const Temporada = Loadable({
  loader: () => import(/* webpackChunkName: "temporada" */ "./temporada"),
  loading: Loading,
  modules: ["temporada"]
});

const Empreendimentos = Loadable({
  loader: () =>
    import(/* webpackChunkName: "empreendimento" */ "./empreendimentos"),
  loading: Loading,
  modules: ["empreendimento"]
});

const Register = Loadable({
  loader: () => import(/* webpackChunkName: "register" */ "./register"),
  loading: Loading,
  modules: ["register"]
});

const PrivacyPolicy = Loadable({
  loader: () =>
    import(/* webpackChunkName: "privacyPolicy" */ "./politica-de-privacidade"),
  loading: Loading,
  modules: ["politica-de-privacidade"]
});

const TermsUse = Loadable({
  loader: () => import(/* webpackChunkName: "termsUse" */ "./termos-de-uso"),
  loading: Loading,
  modules: ["termos-de-uso"]
});

const Routes = ({ empresa_id, favicon }) => {
  let empresa = useContext(EmpresaContext);
  return (
    <div>
      <Helmet>
        {favicon && <link rel="shortcut icon" href={favicon} sizes="64x64" />}
      </Helmet>
      {empresa.body_scripts && parse(empresa.body_scripts)}
      {(!empresa.no_whats || empresa.no_whats === undefined) && (
        <Whatsapp empresa={empresa} />
      )}
      {empresa.db_id !== "0V0NXr6k8TYvw7aFJ7CH" && (
        <CookieBox empresa={empresa} />
      )}
      <Switch>
        <Route exact path="/" component={Home} />
        <Route
          path="**/imovel/:id"
          component={props => (
            <Property apiURL={empresa.url} empresa_id={empresa_id} {...props} />
          )}
        />
        <Route
          path="**/empreendimento/:id"
          component={props => (
            <Condominium
              apiURL={empresa.url}
              empresa_id={empresa_id}
              {...props}
            />
          )}
        />
        <Route
          path="/perfilImovel"
          render={({ location }) => (
            <Redirect
              to={{
                pathname: `/imovel/${new URLSearchParams(location.search).get(
                  "id_imovel"
                )}`
              }}
            />
          )}
        />
        <Route path="/sobre" component={About} />
        <Route path="/contato" component={Contact} />
        <Route path="/politica-de-privacidade" component={PrivacyPolicy} />
        <Route path="/termos-de-uso" component={TermsUse} />
        <Route path="/registrar-imovel" component={Register} />
        <Route path="**/lista/:transacao?/:tipo?" component={Lista} />
        <Route path="**/temporada/:transacao?/:tipo?" component={Temporada} />
        <Route
          path="**/empreendimentos/:transacao?/:tipo?"
          component={Empreendimentos}
        />
        <Route component={Error} />
      </Switch>
      <style>
        {`
          :root {
            --main-color-one: ${empresa.palleta.cor_primaria};
            --main-color-two: ${empresa.palleta.cor_secundaria};
          }
          ${
            empresa.logo_use_bg === false
              ? `
          a.footer-logo::before,
          .logo.readeal-top::before {
            content: unset
          }
          `
              : ""
          }
          ${
            empresa.about_logo_bg === false
              ? `
            .shape-image-list img.shadow-img {
              box-shadow: none;
            }
            `
              : `
            img.shadow-img {
              background-color: rgba(0, 0, 0, 0.3);
            }
            .shape-image-list:after {
              content: "";
              position: absolute;
              height: 205px;
              width: 230px;
              background: var(--main-color-one);
              z-index: -1;
              border-radius: 8px;
            }
          `
          }
          ${empresa.overflow_search_bar &&
            `
            .sitebar .widget {
              max-height: 75vh;
              overflow: auto;
              overflow-x: hidden;
              scrollbar-width: auto;
              scrollbar-color: var(--main-color-one) transparent;
            }
          
            .sitebar .widget::-webkit-scrollbar {
              width: 10px;
            }
          
            .sitebar .widget::-webkit-scrollbar-track {
              background: transparent;
            }
          
            .sitebar .widget::-webkit-scrollbar-thumb {
              background-color: var(--main-color-one);
              border-radius: 10px;
              border: 3px solid transparent;
            }
            `}
          ${empresa.marca_da_agua &&
            `
            .watermark::after {
              content: "";
              display: block;
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              ${(empresa.marca_da_agua.ocultar === false ||
                empresa.marca_da_agua.ocultar === undefined) &&
                `
                opacity: ${empresa.marca_da_agua.opacity || "0.6"};
                background-image: url(${empresa.marca_da_agua.url ||
                  empresa.logo});
              `}
              background-size: ${empresa.marca_da_agua.size || "25%"};
              background-position: ${
                empresa.marca_da_agua.posicao
                  ? empresa.marca_da_agua.posicao == 0
                    ? "center center"
                    : empresa.marca_da_agua.posicao == 1
                      ? "5% 95%"
                      : empresa.marca_da_agua.posicao == 2
                        ? "95% 95%"
                        : empresa.marca_da_agua.posicao == 3
                          ? "5% 10%"
                          : empresa.marca_da_agua.posicao == 4
                            ? "95% 10%"
                            : "center center"
                  : "center center"
              };
              background-repeat: no-repeat;
              z-index: 3334;
            }`}`}
      </style>
    </div>
  );
};

export default Routes;

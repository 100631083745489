import React, { useContext } from "react";
import EmpresaContext from "../../context";
import Styled from "styled-components";
import { formatPhone } from "../../utils/util_functions";

const WhatsStyle = Styled.div`
  a.btn-whats {
    position: fixed;
    background: #25d366;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    right: 20px;
    bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.25s ease;
    z-index: +5;
    box-shadow: 1px 1px 13px 1px #25d366;
    ${props =>
      props.empresa.info_gen &&
      props.empresa.info_gen.whats_animation &&
      "animation: shake 1s infinite;"}
  }
  a.btn-whats:hover {
    transform: scale(1.25);
  }
  a.btn-whats i {
    color: #fff;
    font-size: 54px;
  }
  @media screen and (max-width: 560px) {
    a.btn-whats {
      width: 52px;
      height: 52px;
      bottom: 26px;
    }
    a.btn-whats i {
      font-size: 1.5rem;
    }
  }
  @keyframes shake {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  50% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  55% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  65% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  70% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  75% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  80% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  85% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  90% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  95% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
`;

const ButtonWhats = initialState => {
  let context = useContext(EmpresaContext);
  const WhatsBtn = props => {
    return (
      <WhatsStyle empresa={context}>
        <a
          href={`https://api.whatsapp.com/send?phone=55${formatPhone(
            props.telefone
          )}`}
          target="_blank"
          className="btn-whats"
          style={
            context.scripts_extras && context.scripts_extras.includes("tawk")
              ? { bottom: "88px" }
              : {}
          }
        >
          <i className="fa fa-whatsapp" />
        </a>
      </WhatsStyle>
    );
  };

  return context.telefones_empresa && context.telefones_empresa.length > 0 ? (
    <WhatsBtn
      telefone={
        context.telefones_empresa.filter(
          telefone => telefone.whatsapp === true
        )[0].telefone
      }
    />
  ) : context.telefone_empresa && context.telefone_empresa.length > 0 ? (
    <WhatsBtn telefone={context.telefone_empresa[0]} />
  ) : null;
};

export default ButtonWhats;

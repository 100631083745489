import React, { useEffect, useState, useContext } from "react";
import EmpresaContext from "../context";
import Styled from "styled-components";
import { Phone } from "@styled-icons/fa-solid";
import {
  Facebook,
  Instagram,
  Linkedin,
  Tiktok,
  Twitter,
  Youtube,
  Whatsapp
} from "@styled-icons/fa-brands";
import { formatPhone } from "../utils/util_functions";

const UnderConstructionStyle = Styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  z-index: 10000;
  background: #ecf0f1;
  .fa-exclamation-triangle {
    color: ${props => props.empresa.palleta.cor_primaria || "#999"};
    font-size: 45px;
  }
  .card-container {
    display: grid;
    justify-items: center;
    background: #fff;
    padding: 40px;
    border-radius: 5px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    ul {
      display: flex;
      justify-content: space-evenly;
      width: 60%;
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid ${props =>
          props.empresa.palleta.cor_primaria || "#999"};
        border-radius: 50%;
        padding: 7px;
        transition: 250ms ease;
        cursor: pointer;
        svg {
          width: 20px;
          height: 20px;
          color: ${props => props.empresa.palleta.cor_primaria || "#999"};
          transition: 250ms ease;
        }
        &:hover {
          background: ${props => props.empresa.palleta.cor_primaria || "#999"};
          svg {
            color: #fff;
          }
        }
      }
    }
    .phone-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      a {
        /* display: flex;
        align-items: center;
        background: black;
        color: #fff;
        padding: 5px 10px;
        border: 1px solid black;
        border-radius: 4px;
        */
        background: ${props => props.empresa.palleta.cor_primaria || "#999"};
        margin-top: 10px; 
        svg {
          margin-right: 6px;
        }
        /* &:hover {
          background: white;
          color: ${props => props.empresa.palleta.cor_primaria || "#999"};
        } */
      }
    }
  }
  @media (prefers-color-scheme: dark) {
    background: #282828;
    .card-container {
      background: #383838;
      i, h2, h4, p, li, .phone-list {
        color: #ecf0f1;
      }
      ul {
        li {
          border-color: #ecf0f1;
          svg {
            color: #ecf0f1;
          }
        } 
      }
    }
  }
  @media screen and (max-width: 560px) {
    .card-container {
      padding: 20px;
      ul {
        width: 100%;
      }
      .phone-list {
        grid-template-columns: 1fr;
      }
    }
  }
`;

const UnderConstruction: React.FC = props => {
  let context = useContext(EmpresaContext);
  let redesSociaisIcon = {
    facebook: <Facebook />,
    linkedin: <Linkedin />,
    youtube: <Youtube />,
    twitter: <Twitter />,
    instagram: <Instagram />,
    tiktok: <Tiktok />
  };
  return (
    <UnderConstructionStyle empresa={context}>
      <div className="card-container">
        <i className="fa fa-exclamation-triangle" />
        <h2>O site está em construção</h2>
        <h4>Contato e redes sociais</h4>
        <ul>
          {context.redes_sociais.map(item => (
            <li
              key={item.nome}
              className="social-midia-btn"
              onClick={() => window.open(item.url)}
            >
              {redesSociaisIcon[item.nome]}
            </li>
          ))}
        </ul>
        <div className="phone-list">
          {context.telefones_empresa &&
            context.telefones_empresa.map(telefone => {
              if (telefone.whatsapp) {
                return (
                  <a
                    className="btn btn-yellow"
                    href={`https://api.whatsapp.com/send?phone=55${formatPhone(
                      telefone.telefone
                    )}`}
                    target="_blank"
                  >
                    <Whatsapp size={20} />
                    {telefone.telefone}
                  </a>
                );
              }
              return (
                <a
                  className="btn btn-yellow"
                  href={`tel:${telefone.telefone}`}
                  target="_blank"
                >
                  <Phone size={20} />
                  {telefone.telefone}
                </a>
              );
            })}
        </div>
      </div>
    </UnderConstructionStyle>
  );
};

export default UnderConstruction;
